<template>
   <v-app>
      <v-main>
         <v-form ref="form" @submit.prevent="login" class="d-flex justify-center align-center">
            <v-card dark color="rgba(0, 0, 0, 0.6)" width="480">
               <v-card-title>Welcome</v-card-title>
               <v-card-text class="py-0">{{message}}</v-card-text>
               <v-card-text class="px-10">
                  <v-text-field append-icon="mdi-account" label="Email" type="text" v-model="fm.email" :rules="usrRules" />
                  <v-text-field append-icon="mdi-account-key" label="Password" type="password" v-model="fm.password" :rules="pwdRules" />
               </v-card-text>
               <v-card-actions class="pb-8 justify-space-around">
                  <v-btn text color="blue-grey" to="/forgot-password" >lupa password</v-btn>
                  <v-btn outlined rounded width="120px" color="primary" type="submit" :loading="loading">Login</v-btn>
               </v-card-actions>
            </v-card>
         </v-form>
      </v-main>
      <alert />
   </v-app>
</template>

<script>
import api from '../api';
import Alert from '../components/Alert.vue';
export default {
   components: { Alert },
   data: () => ({
      fm: {},
      usrRules: [v => !!v || 'Email harus diisi', v => /.+@.+\..+/.test(v) || 'Email tidak valid'],
      pwdRules: [v => !!v || 'Password harus diisi' ],
      loading: false,
      token: null,
      message: 'please login to continue'
   }),
   mounted() {
      if(this.$store.state.auth.loggingIn) {
         this.$router.push('/dashboard');
      }
   },
   methods: {
      login() {
         if(!this.$refs.form.validate()) return;
         this.loading = true;
         api.login(this.fm).then(({error, data}) => {
            if(error) {
               this.$alert({title: 'Ooops!', text: error, type: 'warning'});
               this.fm.password = '';
            } else {
               // this.$alert({ type: 'success', title: success, showConfirmButton: false, timer: 1500 });
               console.log('log dat', data);
               this.$store.commit('auth/set', data);
               this.$router.push('/dashboard');
            }
         },
         () => {
            this.$alert('Ooops!', 'Terjadi kesalahan', 'error');
         }).finally(() => { this.loading = false });
      },
   }
}
</script>
<style lang="css" scoped>
#app::before {
   content: '';
   position: fixed;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   background: url("../assets/bg.jpg") no-repeat center center;
   background-size: cover;
   opacity: .5;
   will-change: transform;
   /* z-index: -1; */
}
form {height: 100%;}
</style>